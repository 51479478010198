module.exports = {
	form: {
		"submit": "Submit",
		"enter": "Please enter ",
		"nickname": "nickname",
		"oldPwd": "old password",
		"newPwd": "new password",
		"confirmPwd": "confirm password",
		"Enter format pwd": "Please enter a password in the correct format",
		"Enter delivery address": "Please enter your delivery address",
		"Enter mobile number": "Please enter your mobile number",
		"Pwd incon": "Passwords are inconsistent"
	},
	msg: {
		"No login": "No login account yet, please login first!",
		"Send Suc": "Send successfully"
	},
	face: {
		"Custom settings": "Custom settings",
		"photo_tutorial": "photo tutorial",
		"photo_manual": "manual",
		"photo_automatic": "automatic",
		"not_support": "not support",
		"msg_turnon": "Turn on automatic photographing?",
		"moremsg_turnon": "After opening, take photos automatically after getting the face on the customization page",
		"Provided by me": "Provided by me",
		"Provided by Ta": "Provided by Ta",
		"Give to friends": "Give to friends",
		"Identifying": "Identifying...",
		"look_tips":"Please head up front camera",
		"hold_still":"We're taking pictures. Please keep still",
		"not_support":"Your browser does not support accessing user media devices",
		"visit_error":"Failed to access user media",
		"no_access":"Failed to access media, please check the camera permissions",
		"testing":"Photo recognition....",
		"photo_tutorial":"Photo tutorial",
		"rephotograph": "Take another picture",
		"no": "no",
		"yes": "yes",
		"photo_info": "In order to more accurately detect the skin state, please take off your glasses, remove your makeup and clean your face, and shoot in soft light before shooting"
	},
	skin: {
		"improve_behavior": "improve behavior",
		"health_advice": "health advice",
		"your skin score": "Your comprehensive skin evaluation score is",
		"you are better than": "Your comprehensive score is better than that of ",
		"of your peers": " of your peers",
		"view my scheme": "View my exclusive scheme"
	},
	navigation: {
		"LOGO" : "LOGO",
		"About special me": "About special me",
		"Brand Story": "Brand Story",
		"Press": "Press",
		"FAQS": "FAQS",
		"Personalized": "Personalized",
		"Intelligent AI customization": "Intelligent AI customization",
		"Product introduction": "Product introduction",
		"Login": "Login",
		"recommend": "Recommend",
		"Scan code to enter wechat applet": "Scan code to enter wechat applet",
		"phone number": "phone number",
		"Please enter your mobile number": "Please enter your mobile number",
		"Please enter email": "Please enter email",
		"Unable to scan QR code": "Unable to scan QR code?",
		"Go to the questionnaire for skin measurement": "Start questionnaire",
		"Customized": "Customized",
		"Personal Center": "Personal Center",
		"Please enter the content": "Please enter the content",
		"switch language": "switch language",
		"mail": "mail",
		"legal provisions": "legal provisions",
		"Privacy clause": 'Privacy clause',
		"System settings": "System settings"
	},
	home: {
		"Personalized Process Demo": "Personalized Process Demo",
		"individualization": "individualization",
		"Scientific": "Scientific",
		"Simple and efficient": "Simple and efficient",
		"Products": "Products",
		"Competitive product analysis": "Competitive product analysis",
		"Customers": "Customers' Cases",
		"year": "year",
		"environment": "environment",
		"follow": "follow",
		"component": "component",
		"about": "about",
		"contact us": "contact us",
		"help": "help",
		"Cooperation platform": "Official community link"
	},
	account: {
		"Email": "Email",
		"Password": "Password",
		"Confirm password": "Confirm password",
		"Code": "Code",
		"Avatar": "Avatar",
		"Change Nickname": "Change Nickname",
		"Send Code": "Send Code",
		"Enter pwd": "Please enter password",
		"Enter pwd again": "Please enter the password again",
		"Enter code": "Please enter code",
		"Info pwd inconsistent": "The passwords entered twice are inconsistent",
		"Retrieve pwd": "Retrieve password",
		"Successful retrieval": "Successful retrieval",
		"Retrieve info": "Enter the email address you used to register below, and we will send you a verification code in a few minutes.",
		"changePwdInfo": "For the security of your account, the old and new passwords must be different",
		"changePwdInfo2": "6 ~ 32-bit characters (letters and numbers), not only one character, case sensitive",
	},
	btn: {
		"Confirm": "Confirm",
		"Cancel": "Cancel",
		"Back": "Back",
		"Edit": "Edit"
	},
	login: {
		"Email": "Email",
		"Password": "Password",
		"Please enter password": "Please enter password",
		"Code": "Code",
		"Please enter code": "Please enter code",
		"Mobile num required": "Mobile number cannot be empty",
		"Mobile numb format error": "Mobile number format error",
		"Mailbox required": "Mailbox cannot be empty",
		"Mailbox format error": "Mailbox format error",
		"Change another one": "Change another one",
		"No account, go to register": "No account, go to register",
		"Sign in": "Sign in",
		"Other login methods": "Other login methods",
		"Mobile authentication login": "Mobile authentication login",
		"Mailbox authentication login": "Mailbox authentication login",
		"Password login": "Password login",
		"Send out": "Send out",
		"Sending": "Sending",
		"Login successful": "Login successful"
	},
	reg:{
		"6~32 digit number and letter combination": "6~32 digit number and letter combination",
		"Confirm password": "Confirm password",
		"Please enter the password again": "Please enter the password again",
		"You already have an account, go to login": "You already have an account, go to login",
		"Registration": "Registration",
		"Verification code error": "Verification code error",
		"Password format error": "Password format error",
		"The two passwords are inconsistent": "The two passwords are inconsistent",
		"Registration succeeded, Go to login": "Registration succeeded, Go to login"
	},
	mail: {
		"Customer service poem": "Customer service poem",
		"See more": "See more",
		"Guess you want to ask": "Guess you want to ask",
		"Change a batch": "Change a batch",
		"Click to replace the problem": "Click to replace the problem",
		"Please enter the content you want to consult": "Please enter the content you want to consult"
	},
	intercept: {
		"Logged in account, please log out first": "Logged in account, please log out first",
		"No account is logged in, please log in first": "No account is logged in, please log in first"
	},
	press: {
		"News detail": "News detail"
	},
	story: {
		"Brand vision - women should be beautiful with accessories": "Brand vision - women should be beautiful with accessories",
	},
	record: {
		"Gift giving": "Gift giving",
		"Questionnaire skin measurement": "Questionnaire skin measurement",
		"Mobile phone skin measurement": "Mobile phone skin measurement",
		"How would you like us to get his skin condition": "How would you like us to get his skin condition",
		"By TA provide": "By TA provide",
		"Provided by me": "Provided by me",
		"Fill in": "Fill in the survey questionnaire to scientifically and professionally understand your current skin condition through the information you fill in",
		"Mobile scanning code": "Mobile scanning code",
		"Mobile phone photography": "Mobile phone photography",
		"Mobile browser scanning QR code": "Mobile browser scanning QR code",
		"Skin Report": "Skin Report",
		"Survey questionnaire": "Survey questionnaire",
		"Exclusive scheme": "Exclusive scheme",
		"Please read the agreement and check": "Please read the agreement and check",
		"I am 18 years old": "I am 18 years old",
		"View privacy agreement": "View privacy agreement",
		"View user agreements": "View user agreements",
		"Privacy agreement": "Privacy agreement",
		"and": "and",
		"User agreement": "User agreement",
		"determine": "determine",
		"close": "close",
		"Sorry, you are under the age of 18, We can't provide skin measurement service for you": "Sorry, you are under the age of 18, We can't provide skin measurement service for you",
		"Please read and tick the agreement": "Please read and tick the agreement",
		"Link failed! Please refresh the page and try again": "Link failed! Please refresh the page and try again",
		"User terminates mobile phone skin measurement": "User terminates mobile phone skin measurement"
	},
	measusrement: {
		"Select active ingredients according to your unique needs": "Select active ingredients according to your unique needs",
		"Single choice question": "Single choice",
		"Multiple choice questions": "Multiple choice"
	},
	report: {
		"analyze the problem together": "Let's analyze the problem together",
		"error": "If you feel there is error in the result, please click to select the corresponding",
		"If you feel there is error in the result, please click to select the corresponding": "If you feel there is error in the result, please click to select the corresponding",
		"Type to modify": "Type to modify",
		"Vascular type": "Vascular type",
		"Pigmented type": "Pigmented type",
		"Structural type": "Structural type",
		"Tolerance": "Tolerance",		
		"Mild sensitivity": "Mild sensitivity",		
		"sensitive": "sensitive",		
		"Dryness": "Dryness",		
		"neutral": "neutral",		
		"Oiliness": "Oiliness",
		"nothing": "nothing",		
		"light": "light",		
		"moderate": "moderate",		
		"severe": "severe"
	},
	questionnaire: {
		"prev": "prev",
		"next": "next",
		"text": "text",
		"Please enter your mobile number": "Please enter your mobile number",		
		"Please enter your password": "Please enter your password",		
		"Please enter your email": "Please enter your email",
		"Enter zip code": "Please enter the right zip code",
		"Province/city/district": "Province/city/district",
		"Continue to fill in the questionnaire": "Continue to fill in the questionnaire",
		"reminder": "reminder",
		"Up to": "Up to",
		"term": "term",
		"Please choose your birthday": "Please choose your birthday",		
		"Please select your address": "Please select your address",		
		"Name cannot be empty": "Name cannot be empty",		
		"Select at least one item": "Select at least one item",
		"The account has been registered, Do you want to log in directly": "The account has been registered, Do you want to log in directly",
		"Password format error, combination of 6 ~ 32 digits of numbers and letters": "Password format error, combination of 6 ~ 32 digits of numbers and letters"
	},
	exclusive: {
		"Your customized products": "Your customized products",		
		"Total (excluding freight)": "Total (excluding freight)",		
		"settlement": "settlement",		
		"Select all": "Select all",		
		"Product information": "Product information",		
		"Unit Price": "Unit Price",		
		"quantity": "quantity",		
		"amount of money": "amount of money",		
		"operation": "operation",		
		"Purchased products": "Purchased products",		
		"View details": "View details",		
		"Recommended products": "Recommended products",		
		"Selected items": "Selected items",		
		"piece": "piece",		
		"Product details": "Product details",		
		"original price": "original price",		
		"Preferential unit price": "Preferential unit price",		
		"Place of Origin": "Place of Origin",		
		"sales volume": "sales volume",		
		"Product name": "Product name",		
		"Ingredient information": "Ingredient information",		
		"Select at least one item": "Select at least one item",
		"Import tax: goods have been taxed": "Import tax: goods have been taxed",
		"China": "China",
		"U.S.A": "U.S.A"
	},
	confirm: {
		"Confirm order information": "Confirm order information",
		"Delete item": "Delete item",
		"Consignee information": "Consignee information",
		"More addresses": "More addresses",		
		"Add address": "Add address",		
		"Total amount of goods": "Total amount of goods",		
		"freight": "freight",		
		"Total amount payable": "Total amount payable",		
		"To": "To",
		"place order": "place order",		
		"Select address": "Select address",		
		"Use address": "Use address",
		"Order submission in progress": "Order submission in progress"
	},
	callback: {
		"Open wechat code scanning payment": "Open wechat code scanning payment",		
		"Confirm payment": "Confirm payment",		
		"Cancel payment": "Cancel payment",		
		"View order": "View order",		
		"Payment query in progress": "Payment query in progress",		
		"Payment failed": "Payment failed",		
		"Payment succeeded": "Payment succeeded"
	},
	personal: {
		"modify the Avatar": "modify the Avatar",
		"nickname": "nickname",
		"integral": "integral",
		"ID No": "ID No",
		"Change Password": "Change Password",
		"Forget Password": "Forget Password?",
		"Log out": "Log out",
		"Original password": "Original password",
		"Please enter the original password": "Please enter the original password",
		"New password": "New password",
		"Please enter a new password": "Please enter a new password",
		"Please enter the new password again": "Please enter the new password again",
		"Confirm": "Confirm",
		"Cancel": "Cancel",
		"personal information": "personal information",
		"My order": "My order",
		"recommend": "recommend",
		"Custom records": "Custom records",
		"Address management": "Address management",
		"Password modified successfully": "Password modified successfully",
		"Identity information has expired, please log in again": "Identity information has expired, please log in again",
		"Avatar modified successfully": "Avatar modified successfully",
		"Exit login": "Exit login",
		"Tips": "Tips",
		"sign out": "sign out",
		"Exit successful": "Exit successful"
	},
	masage: {
		"edit": "edit",
		"Please enter a nickname": "Please enter a nickname",
		"Gender": "Gender",
		"secrecy": "secrecy",
		"female": "female",
		"male": "male",
		"birthday": "birthday",
		"Select birthday": "Select birthday",
		"Bind mailbox": "Bind mailbox",
		"Unbound mailbox": "Unbound mailbox",
		"Modify binding": "Modify binding",
		"Binding mobile phone": "Binding mobile phone",
		"Unbound phone": "Unbound phone",
		"Save now": "Save now",
		"Mobile number binding": "Mobile number binding",
		"Original mobile number": "Original mobile number",
		"Please enter your mobile number": "Please enter your mobile number",
		"New mobile number": "New mobile number",
		"Please enter a new phone number": "Please enter a new phone number",
		"Mailbox binding": "Mailbox binding",
		"Original mailbox": "Original mailbox",
		"Please enter email account": "Please enter email account",
		"New mailbox": "New mailbox",
		"Please enter a new email account": "Please enter a new email account",
		"Binding modification succeeded": "Binding modification succeeded",
		"Save change information": "Save change information",
		"Change succeeded": "Change succeeded",
		"Cancel modifying information": "Cancel modifying information",
		"return": "return",
		"Verification code has been sent to email, please check": "Verification code has been sent to email, please check",
		"The verification code has been sent to the mobile phone, Please check it": "The verification code has been sent to the mobile phone | e-mail. Please check it"
	},
	order: {
		"whole": "whole",
		"To be paid": "To be paid",
		"To be shipped": "To be shipped",
		"To be received": "To be received",
		"To be evaluated": "To be evaluated",
		"Completed": "Completed",
		"Cancelled": "Cancelled",
		"Order status": "Order status",
		"order number": "order number",
		"Product name": "Product name",
		"quantity": "quantity",
		"model": "model",
		"original price": "original price",
		"Preferential unit price": "Preferential unit price",
		"Total price": "Total price",
		"Discount": "Discount",
		"Actual payment": "Actual payment",
		"Delete order": "Delete order",
		"cancellation of order": "cancellation of order",
		"View details": "View details",
		"Skin test report": "Skin test report",
		"Pay now": "Pay now",
		"Logistics information": "Logistics information",
		"Confirm receipt": "Confirm receipt",
		"Request invoice": "Request invoice",
		"Invoice details": "Invoice details",
		"Order details": "Order details",
		"Consignee Name": "Consignee Name",
		"Consignee alias": "Consignee alias",
		"Consignee telephone": "Consignee telephone",
		"Express name": "Express name",
		"Harvest area": "Harvest area",
		"Harvest detailed address": "Harvest detailed address",
		"order number": "order number",
		"Order time": "Order time",
		"Order status": "Order status",
		"Original order price": "Original order price",
		"Favorable Price": "Favorable Price",
		"Paid in price": "Paid in price",
		"Online payment": "Online payment",
		"Amount payable": "mount payable",
		"Receiving address": "Receiving address",
		"coupon": "coupon",
		"Select coupon": "Select coupon",
		"Payment method": "Payment method",
		"payment": "payment",
		"Invoice No": "Invoice No",
		"courier number": "courier number",
		"Date of application": "Date of application",
		"Recipient name": "Recipient name",
		"Full name of enterprise": "Full name of enterprise",
		"phone number": "phone number",
		"e-mail address": "e-mail address",
		"Invoiced amount": "Invoiced amount",
		"Actual invoiced amount": "Actual invoiced amount",
		"Drawer": "Drawer",
		"Billing date": "Billing date",
		"Contact address": "Contact address",
		"Modify information": "Modify information",
		"personal": "personal",
		"full name": "full name",
		"Please enter your name": "Please enter your name",
		"mailbox": "mailbox",
		"Please enter email": "Please enter email",
		"Submit": "Submit",
		"enterprise": "enterprise",
		"Enterprise name": "Enterprise name",
		"Please enter the enterprise name": "Please enter the enterprise name",
		"Enterprise tax No": "Enterprise tax No",
		"Please enter the enterprise tax number": "Please enter the enterprise tax number",
		"Business address": "Business address",
		"Please enter the business address": "Please enter the business address",
		"Telephone number": "Telephone number",
		"Please enter the phone number": "Please enter the phone number",
		"Deposit bank": "Deposit bank",
		"Please enter the deposit bank": "Please enter the deposit bank",
		"bank account": "bank account",
		"Please enter the bank account number": "Please enter the bank account number",
		"Do you want to delete this order? It will not be displayed after deletion": "Do you want to delete this order? It will not be displayed after deletion",
		"delete": "delete",
		"cancel": "cancel",
		"Deletion succeeded": "Deletion succeeded",
		"Cancel this order": "Cancel this order",
		"confirm": "confirm",
		"Cancellation succeeded": "Cancellation succeeded",
		"Confirm receipt of the goods": "Confirm receipt of the goods",
		"determine": "determine",
		"Invoice application succeeded": "Invoice application succeeded",
		"Invoice information modified successfully": "Invoice information modified successfully",
		"Order info": "Order info",
		"Order No": "Order No",
		"Receiving information": "Receiving information",
		"Select payment": "Please select a payment method"
	},
	customized: {
		"Modify name": "Modify name",
		"Please enter a title": "Please enter a title",
		"Related orders": "Order", // "Related orders",
		"Customization details": "details", // "Customization details",
		"Successfully edited": "Successfully edited",
		"Customized guidance information": "How do you want to customize it?",
		"Send link": "Send link",
		"Skin Analysis": "Analysis"
	},
	address: {
		"consignee": "consignee",
		"alias": "alias",
		"cell-phone number": "cell-phone number",
		"Location": "Location",
		"Detailed address": "Detailed address",
		"Paste all content smart identification": "Paste all content smart identification",
		"Intelligent identification": "Intelligent identification",
		"Please enter the consignee name": "Please enter the consignee name",
		"Please enter the consignee alias": "Please enter the consignee alias",
		"Please enter the mobile number of the consignee": "Please enter the mobile number of the consignee",
		"Please enter the address": "Please enter the address",
		"preservation": "preservation",
		"Default address": "Default address",
		"Please enter the detailed harvest address": "Please enter the detailed harvest address",
		"Please select an address": "Please select an address",
		"Edit information": "Edit information",
		"Delete address information": "Delete address information",
		"Set as default address": "Set as default address",
		"Set successfully": "Set successfully",
		"Address analysis in progress": "Address analysis in progress",
		"Please enter address information": "Please enter address information",
		"Add address": "Add address",
		"Please fill in the address": "Please fill in the address",
		"Successfully added address": "Successfully added address",
		"Address modified successfully": "Address modified successfully",
		"Set as default": "Set as default"
	}
}