import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决重复点击跳转报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('../views/home/Home.vue')
	},
	{
		path: '/mail',
		name: 'mail',
		component: () => import('../views/about/mail.vue')
	},
	{
		path: '/story',
		name: 'story',
		component: () => import('../views/about/story.vue')
	},
	{
		path: '/press',
		name: 'press',
		component: () => import('../views/news/list.vue')
	},
	{
		path: '/news/item',
		name: 'NewsItem',
		component: () => import('../views/news/detail.vue')
	},
	{
		path: '/faqs',
		name: 'faqs',
		component: () => import('../views/about/faqs.vue')
	},
	{
		path: '/recommend',
		name: 'recommend',
		component: () => import('../views/recommend/list.vue'),
		meta: {
			layout: 'mobile'
		}
	},
	{
		path: '/recommend/item',
		name: 'RecommendDetail',
		component: () => import('../views/recommend/detail.vue'),
		meta: {
			layout: 'mobile'
		}
	},
	{
		path: '/detail',
		name: 'detail',
		component: () => import('../views/article/detail.vue')
	},
	{
		path: '/product',
		name: 'product',
		component: () => import('../views/product/index.vue')
	},
	{
		path: '/product/item',
		name: 'ProductItem',
		component: () => import('../views/product/item.vue'),
		meta: {
			layout: 'mobile'
		}
	},
	{
		path: '/contactus',
		name: 'contactus',
		component: () => import('../views/contact/email.vue')
	},
	{
		path: '/terms',
		name: 'terms',
		component: () => import('../views/article/terms.vue')
	},
	{
		path: '/help',
		name: 'help',
		component: () => import('../views/help/list.vue')
	},
	{
		path: '/personal',
		name: 'personal',
		redirect: '/personal/masage',
		component: () => import('../views/personals/index.vue'),
		beforeEnter: (to,form,next) => {
			const userInfo = JSON.parse(localStorage.getItem('userInfo')) || ''
			if(userInfo){
				next()
				return
			}
			next('/intercept')
		},
		children: [
			{
				path: 'address',
				name: 'address',
				component: () => import('../views/personals/address.vue'),
				beforeEnter: (to,form,next) => {
				const userInfo = JSON.parse(localStorage.getItem('userInfo')) || ''
				if(userInfo){
					next()
					return
				}
				next('/intercept')
			},
			},
			{
				path: 'customized',
				name: 'customized',
				component: () => import('../views/personals/customized.vue'),
			},
			{
				path: 'recommend',
				name: 'precommend',
				component: () => import('../views/personals/recommend.vue'),
			},
			{
				path: 'masage',
				name: 'masage',
				component: () => import('../views/personals/masage.vue'),
			},
			{
				path: 'order',
				name: 'order',
				component: () => import('../views/order/list.vue'),
			},
			{
				path: 'order/detail',
				name: 'OrderDetailH5',
				component: () => import('../views/order/detail.vue'),
			},
			{
				path: 'order/logistics',
				name: 'OrderLogistics',
				component: () => import('../views/order/logistics.vue'),
			},
			{
				path: 'set',
				name: 'set',
				component: () => import('../views/personals/set.vue'),
			},
		]
	},
	{
		path: '/ucenter',
		name: 'ucenter',
		component: () => import('../views/ucenter/index.vue')
	},
	{
		path: '/account/changepwd',
		name: 'ChangePwd',
		component: () => import('../views/ucenter/changepwd.vue')
	},
	{
		path: '/ucenter/order',
		name: 'MyOrder',
		component: () => import('../views/order/list.vue'),
	},
	{
		path: '/ucenter/order/logistics',
		name: 'UcenterOrderLogistics',
		component: () => import('../views/order/logistics.vue'),
	},
	{
		path: '/ucenter/customized',
		name: 'MyCustomized',
		component: () => import('../views/personals/customized.vue'),
	},
	{
		path: '/ucenter/recommend',
		name: 'MyRecommend',
		component: () => import('../views/personals/recommend.vue'),
	},
	{
		path: '/ucenter/userinfo',
		name: 'UserInfo',
		component: () => import('../views/ucenter/userinfo.vue'),
	},
	{
		path: '/ucenter/setups',
		name: 'Setups',
		component: () => import('../views/ucenter/setups.vue'),
	},
	{
		path: '/ucenter/nickname',
		name: 'MyNickname',
		component: () => import('../views/ucenter/nickname.vue'),
	},
	{
		path: '/ucenter/bindingAccount',
		name: 'BindingAccount',
		component: () => import('../views/ucenter/bindingAccount.vue'),
	},
	{
		path: '/ucenter/address',
		name: 'MyAdress',
		component: () => import('../views/personals/address.vue'),
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/member/login.vue'),
		beforeEnter: (to,form,next) => {
			const userInfo = JSON.parse(localStorage.getItem('userInfo'))  || ''
			if(!userInfo){
				next()
				return
			}
			next('/intercept')
		},
		
	},
	{
		path: '/member/forgetpassword',
		name: 'forgetpassword',
		component: () => import('../views/member/forgetpassword.vue')
	},
	{
		path: '/intercept',
		name: 'intercept',
		component: () => import('../views/intercept.vue')
	},
	{
		path: '/face/choice',
		name: 'face',
		component: () => import('../views/face/choice.vue')
	},
	{
		path: '/face/detection',
		name: 'FaceDetection',
		component: () => import('../views/face/detection.vue')
	},
	{
		path: '/face/tester',
		name: 'FaceTester',
		component: () => import('../views/face/tester.vue')
	},
	{
		path: '/face/testing',
		name: 'FaceTesting',
		component: () => import('../views/face/testing.vue'),
		meta: {
			layout: 'mobile'
		}
	},
	{
		path: '/face/photo_tutorial',
		name: 'PhotoTutorial',
		component: () => import('../views/face/tutorial.vue')
	},
	{
		path: '/record',
		name: 'record',
		component: () => import('../views/skin/record.vue'),
	},
	{
		path: '/photograph',
		name: 'photograph',
		component: () => import('../views/skin/photograph.vue')
	},
	{
		path: '/scanning',
		name: 'scanning',
		component: () => import('../views/skin/scanning.vue')
	},
	{
		path: '/report',
		name: 'report',
		component: () => import('../views/skin/report.vue')
	},
	{
		path: '/questionnaire',
		name: 'questionnaire',
		component: () => import('../views/skin/questionnaire.vue')
	},
	{
		path: '/skin/analysis',
		name: 'SkinAnalysis',
		component: () => import('../views/skin/analysis.vue')
	},
	{
		path: '/exclusive',
		name: 'exclusive',
		component: () => import('../views/product/exclusive.vue')
	},
	{
		path: '/confirm',
		name: 'confirm',
		component: () => import('../views/order/confirm.vue')
	},
	{
		path: '/order/detail',
		name: 'OrderDetail',
		component: () => import('../views/order/detail.vue')
	},
	{
		path: '/measurement',
		name: 'measurement',
		component: () => import('../views/skin/measurement.vue')
	},
	{
		path: '/callback',
		name: 'callback',
		component: () => import('../views/order/callback.vue')
	},
	{
		path: '/test/list',
		name: 'testList',
		component: () => import('../views/test/list.vue')
	},
	{
		path: '/test/canvas',
		name: 'testCanvas',
		component: () => import('../views/test/canvas.vue')
	},
]

const router = new VueRouter({
	routes,
	// 判断返回页面还是跳转页面，解决滚动条距离
	scrollBehavior(to, from, savedPosition) {
		return savedPosition ? savedPosition : {
			x: 0,
			y: 0
		}
	}
})

export default router
