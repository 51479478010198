//
//
//
//
//
//
//
export default {
  name: '',
  props: {},

  data() {
    return {
      showFooter: true
    };
  },

  computed: {},

  beforeRouteEnter() {
    const isMobile = /Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(navigator.userAgent);
    const hash = window.location.hash.slice(2);
    console.log('beforeRouteEnter', isMobile, hash); // this.showFooter = (!isMobile || (isMobile && hash === '')) ? true : false
  },

  watch: {},

  created() {
    console.log(window.location.hash.slice(2) === '');
  },

  mounted() {},

  methods: {}
};