//
//
//
//
//
//
//
//
import Layout from '@/layout/index';
import 'animate.css';
export default {
  components: {
    Layout
  },

  data() {
    return {
      default_layout: 'default'
    };
  },

  computed: {
    layout() {
      return (this.$route.meta.layout || this.default_layout) + '-layout';
    }

  },

  created() {
    localStorage.setItem('country', 'en');
  },

  methods: {}
};