//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import QRCode from 'qrcodejs2-fix';
import { Message } from 'element-ui';
export default {
  props: {
    nameId: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'right'
    }
  },

  data() {
    const isMobile = /Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(navigator.userAgent);
    return {
      isMobile,
      phoneNum: '',
      hoverPopover: 100,
      popover: false,
      popover1: false,
      popover2: false
    };
  },

  mounted() {
    new QRCode(this.nameId, {
      width: 150,
      // 二维码宽度，单位像素
      height: 150,
      // 二维码高度，单位像素
      text: 'https://pc-test.aacclub.cn/#/record' // `https://www-test.aacclub.cn/mobile/face/choice.html` // 生成二维码的链接

    });
  },

  methods: {
    // showPopover1() {
    //     const { popover, popover1, popover2 } = this
    //     if ()
    // },
    hidePopover() {// const { popover, popover1, popover2 } = this
      // if (popover1) {
      //     this.popover = true
      //     this.popover1 = true
      // } else if (popover2) {
      //     this.popover = true
      //     this.popover2 = true
      // }
    },

    hidePopover1() {},

    hidePopover2() {},

    sendMsg() {
      this.$http.post('/index/user/skintesturlsend', {
        type: 'sms',
        accounts: this.phoneNum
      }, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(res => {
        const {
          code,
          msg
        } = res;

        if (code == 0) {
          Message({
            type: 'success',
            message: msg
          });
          this.popover1 = false;
        }
      });
    },

    toSkinMeasurement() {
      this.$router.push({
        name: 'questionnaire',
        query: {
          qnrType: 1
        }
      });
    }

  }
};