//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data() {
    return {
      about: [],
      contact: [],
      help: [],
      // image.png
      community: [require('../../assets/icons/ins.jpeg'), require('../../assets/icons/facebook.jpeg'), require('../../assets/icons/Pinterest.jpeg'), require('../../assets/icons/douyin.svg'), require('../../assets/icons/youtube.jpeg')],
      brand: [require('../../assets/icons/weixin.svg'), require('../../assets/icons/weibo.svg'), require('../../assets/icons/zhifubao.svg'), require('../../assets/icons/douyin.svg'), require('../../assets/icons/qq.svg')],
      contact: [{
        key: '联系电话',
        value: '021-88888888'
      }, {
        key: '联系邮箱',
        value: '021-88888888',
        link: 'contactus'
      }, {
        key: '联系地址',
        value: '021-88888888'
      }]
    };
  },

  created() {
    this.getData();
  },

  methods: {
    // 获取首页数据
    getData() {
      this.$http.post('/index/index/PcFooter', {}, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(res => {
        if (res.code == 0) {
          // this.about = res.data.about
          this.contact = res.data.contact;
          this.help = res.data.help;
        }
      });
    },

    // 帮助详情
    toDetail(item) {
      const {
        jump_url,
        id
      } = item;

      if (jump_url) {
        window.location.href = jump_url;
      } else {
        this.$router.push({
          name: 'detail',
          query: {
            id
          }
        });
      }
    },

    // 协议类详情
    toTerms(type) {
      this.$router.push({
        name: 'terms',
        query: {
          type
        }
      });
    }

  }
};