import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from '@/axios/axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import 'vant/lib/index.css';
import './assets/styles/index.scss'
import { Locale } from 'vant';
import enUS from 'vant/es/locale/lang/en-US';
import VueI18n from 'vue-i18n'
import VeRadar from 'v-charts/lib/radar.common'
import DefaultLayout from './layout/index'
import MobileLayout from './layout/mobile'
Vue.component(VeRadar.name, VeRadar)
Vue.component('default-layout', DefaultLayout)
Vue.component('mobile-layout', MobileLayout)
// import SocialSharing from 'vue-social-sharing'
// import Share from 'vue-social-share'
// import 'vue-social-share/dist/client.css'
// import 'social-share.js/dist/js/social-share.min.js'
// import SocialSharing from 'vue-social-sharing'

// Vue.use(SocialSharing);
import vshare from 'vshare'
Vue.use(vshare)


Vue.prototype.$http = axios
Vue.prototype.$boss = new Vue()
Vue.use(ElementUI);
locale.use(lang)
Locale.use('en-US', enUS);
Vue.use(VueI18n)

const i18n = new VueI18n({
    locale:localStorage.getItem('country')||'en',   //从localStorage里获取用户中英文选择，没有则默认中文
    messages:{
        'zh':require('./lang/zh'),
        'en':require('./lang/en')
    }
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
