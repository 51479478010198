import Vue from 'vue'
import Vuex from 'vuex'
import axios from '../axios/axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  userInfo: {}
  },
  getters: {
  },
  mutations: {
    setUserInfo(state, payload) { 
      state.userInfo = payload
    }
  },
  actions: {
    getUserInfo({ commit }) {
      new Promise((resolve, reject) => {
        axios.post('/index/user/login', params).then(res => {
          if (res.code == 0) {
            commit('setUserInfo', res.data)
						localStorage.setItem('userInfo', JSON.stringify(res.data))
						localStorage.removeItem('path')
            resolve(res)
          } else {
            reject(res)
          }
				})
      })
    }
  },
  modules: {
  }
})
