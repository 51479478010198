module.exports = {
	form: {
		"submit": "提交",
		"enter": "请输入",
		"nickname": "昵称",
		"oldPwd": "原密码",
		"newPwd": "新密码",
		"confirmPwd": "确认密码",
		"Enter format pwd": "请输入正确格式的密码",
		"Enter delivery address": "请输入地址",
		"Enter mobile number": "请输入手机号",
		"Pwd incon": "密码不一致",
	},
	msg: {
		"No login": "暂未登录账号，请先登录！",
		"Send Suc": "发送成功"
	},
	face: {
		"Custom settings": "定制设置",
		"photo_tutorial": "拍照教程",
		"photo_manual": "手动拍照",
		"photo_automatic": "自动拍照",
		"not_support": "不支持",
		"msg_turnon": "是否开启自动拍照？",
		"moremsg_turnon": "开启后在定制页页面获取到人脸后自动进行拍照",
		"Provided by me": "由我提供",
		"Provided by Ta": "由Ta提供",
		"Give to friends": "赠送朋友",
		"Identifying": "正在识别中。。。",
		"look_tips": "请平视前置摄像头",
		"hold_still":"'正在拍照，请保持不动'",
		"not_support":"你的浏览器不支持访问用户媒体设备",
		"visit_error":"访问用户媒体失败",
		"no_access":"访问媒体失败，请查看摄像头权限",
		"testing":"照片识别中....",
		"photo_tutorial":"拍照教程",
		"rephotograph": "重新拍照",
		"no": "否",
		"yes": "是",
		"photo_info": "为了更精准检测肌肤状态，拍摄前，请摘掉眼镜、卸妆洁面、在柔和的光线下拍摄"
	},
	skin: {
		"improve_behavior": "首要改善行为",
		"health_advice": "健康生活建议",
		"your skin score": "您的肌肤测评综合评分是",
		"you are better than": "您的综合评分比",
		"of your peers": "的同龄段人更好",
		"view my scheme": "查看我的独家计划"
	},
	navigation: {
		"LOGO" : "图标",
		"About special me": "关于施蓓密",
		"Brand Story": "品牌故事",
		"Press": "新闻媒体报道",
		"FAQS": "常见问题",
		"Intelligent AI customization": "智能AI定制",
		"Product introduction": "产品介绍",
		"Login": "登录",
		"Personalized": "测肤",
		"recommend": "种草频道",
		"Scan code to enter wechat applet": "扫码进入微信小程序",
		"phone number": "手机号",
		"Please enter your mobile number": "请输入手机号",
		"Please enter email": "请输入邮箱",
		"Unable to scan QR code": "无法扫描二维码？",
		"Go to the questionnaire for skin measurement": "前往问卷测肤",
		"Customized": "专属定制",
		"Personal Center": "个人中心",
		"Please enter the content": "请输入内容",
		"switch language": "切换语言",
		"mail": "站内信",
		"legal provisions": "法律条款",
		"Privacy clause": '隐私条款',
		"System settings": "系统设置"
	},
	home: {
		"Personalized Process Demo": "测肤流程",
		"individualization": "个性化",
		"Scientific": "科学的",
		"Simple and efficient": "简单高效",
		"Products": "产品",
		"Competitive product analysis": "竞品分析",
		"Customers": "客户案例",
		"year": "岁",
		"environment": "环境",
		"follow": "关注",
		"component": "成分",
		"about": "关于",
		"contact us": "联系我们",
		"help": "帮助",
		"Cooperation platform": "官号社群链接"
	},
	account: {
		"Email": "邮箱",
		"Password": "密码",
		"Confirm password": "确认密码",
		"Code": "验证码",
		"Avatar": "更改头像",
		"Change Nickname": "更改昵称",
		"Send Code": "发送验证码",
		"Enter pwd": "请输入密码",
		"Enter pwd again": "请再次输入密码",
		"Enter code": "请输入验证码",
		"Info pwd inconsistent": "两次输入的密码不一致",
		"Retrieve pwd": "找回密码",
		"Successful retrieval": "找回成功",
		"Retrieve info": "在下面输入您注册时使用的电子邮件地址，我们将在几分钟内发送给您一个验证码。",
		"changePwdInfo": "为了您的账号安全，新旧密码必须不同",
		"changePwdInfo2": "6~32位字符（字母、数字），不能只是用一种字符，区分大小写"
	},
	btn: {
		"Confirm": "确认",
		"Cancel": "取消",
		"Back": "返回",
		"Edit": "编辑"
	},
	login: {
		"Email": "邮箱",
		"Password": "密码",
		"Please enter password": "请输入密码",
		"Code": "验证码",
		"Please enter code": "请输入验证码",
		"Mobile num required": "手机号不能为空",
		"Mobile num format error": "手机号格式错误",
		"Mailbox required": "邮箱不能为空",
		"Mailbox format error": "邮箱格式错误",
		"Change another one": "看不清,换一张",
		"No account, go to register": "暂无账号，去注册",
		"Sign in": "登 录",
		"Other login methods": "其他登陆方式",
		"Mobile authentication login": "手机验证登录",
		"Mailbox authentication login": "邮箱验证登录",
		"Password login": "密码登录",
		"Send out": "发送",
		"Sending": "发送中",
		"Login successful": "登录成功"
	},
	reg:{
		"6~32 digit number and letter combination": "6~32位数字和字母组合",
		"Confirm password": "确认密码",
		"Please enter the password again": "请再次输入密码",
		"You already have an account, go to login": "已有账号，去登录",
		"Registration": "注 册",
		"Verification code error": "验证码错误",
		"Password format error": "密码格式错误",
		"The two passwords are inconsistent": "两次密码不一致",
		"Registration succeeded, Go to login": "注册成功，前往登录"
	},

	mail: {
		"Customer service poem": "客服小诗",
		"See more": "查看更多",
		"Guess you want to ask": "猜你想问",
		"Change a batch": "换一批",
		"Click to replace the problem": "点击更换问题",
		"Please enter the content you want to consult": "请输入想要咨询的内容"
	},
	intercept: {
		"Logged in account, please log out first": "已登录账号，请先退出登录！",
		"No account is logged in, please log in first": "暂未登录账号，请先登录"
	},
	press: {
		"News detail": "新闻详情"
	},
	story: {
		"Brand vision - women should be beautiful with accessories": "品牌愿景 —— 女性应配饰而美好",
	},
	record: {
		"Gift giving": "送礼",
		"Questionnaire skin measurement": "立即进行问卷测肤",
		"Mobile phone skin measurement": "立即进行手机测肤",
		"How would you like us to get his skin condition": "你希望我们如何获取他的皮肤情况",
		"By TA provide": "由Ta提供",
		"Provided by me": "由我提供",
		"Fill in": "填写调研问卷,通过您填写的信息，科学、专业了解您当前的肌肤状况",
		"Mobile scanning code": "手机扫码",
		"Mobile phone photography": "手机拍照",
		"Mobile browser scanning QR code": "手机浏览器扫描二维码",
		"Skin Report": "肌肤报告",
		"Survey questionnaire": "调研问卷",
		"Exclusive scheme": "专属方案",
		"Please read the agreement and check": "请阅读协议并勾选",
		"I am 18 years old": "我已满18岁",
		"View privacy agreement": "查看隐私协议",
		"View user agreements": "查看用户协议",
		"Privacy agreement": "《隐私协议》",
		"and": "和",
		"User agreement": "《用户协议》",
		"determine": "确 定",
		"close": "关 闭",
		"Sorry, you are under the age of 18, We can't provide skin measurement service for you": "抱歉，您未满18周岁，无法为您提供测肤服务",
		"Please read and tick the agreement": "请阅读并勾选协议",
		"Link failed! Please refresh the page and try again": "链接失败！请刷新页面后重试",
		"User terminates mobile phone skin measurement": "用户终止手机测肤"
	},
	measusrement: {
		"Select active ingredients according to your unique needs": "根据您的独特需求挑选活性成分",
		"Single choice question": "单选",
		"Multiple choice questions": "多选"
	},
	report: {
		"analyze the problem together": "让我们一起来分析一下问题",
		"error": "如果您感觉结果有误差，请点击选择对应的",
		"If you feel there is error in the result, please click to select the corresponding": "如果您感觉结果有误差，请点击选择对应的",
		"Type to modify": "类型进行修改",
		"Vascular type": "血管型",
		"Pigmented type": "色素型",
		"Structural type": "结构型",
		"Tolerance": "耐受",
		"Mild sensitivity": "轻度敏感",
		"sensitive": "敏感"	,	
		"Dryness": "干性",	
		"neutral": "中性",		
		"Oiliness": "油性",
		"nothing": "无",		
		"light": "轻度",		
		"moderate": "中度",		
		"severe": "重度",
	},
	questionnaire: {
		"prev": "上一题",
		"next": "下一题",
		"text": "文本",
		"Please enter your mobile number": "请输入你的手机号",
		"Please enter your password": "请输入你的密码",		
		"Please enter your email": "请输入你的邮箱",
		"Enter zip code": "请输入正确的邮编",
		"Province/city/district": "省/市/区",
		"Continue to fill in the questionnaire": "是否继续填写问卷",
		"reminder": "温馨提示",
		"Up to": "最多可以选择",
		"term": "项",
		"Please choose your birthday": "请选择自己的生日",
		"Please select your address": "请选择所在地址",		
		"Name cannot be empty": "姓名不能为空",		
		"Select at least one item": "最少选择一项",
		"The account has been registered, Do you want to log in directly": "账号已注册，是否直接登录",
		"Password format error, combination of 6 ~ 32 digits of numbers and letters": "密码格式错误,6 ~ 32位数字和字母的组合"
	},
	exclusive: {
		"Your customized products": "您的专属定制产品",		
		"Total (excluding freight)": "合计（不含运费）",		
		"settlement": "结算",		
		"Select all": "全选",		
		"Product information": "商品信息",		
		"Unit Price": "单价",		
		"quantity": "数量",		
		"amount of money": "金额",		
		"operation": "操作",		
		"Purchased products": "已购买产品",		
		"View details": "查看详情",		
		"Recommended products": "推荐产品",		
		"Selected items": "已选商品",		
		"piece": "件",		
		"Product details": "商品详情",		
		"original price": "原价",		
		"Preferential unit price": "优惠单价",		
		"Place of Origin": "产地",		
		"sales volume": "销量",		
		"Product name": "产品名称",		
		"Ingredient information": "成分信息",		
		"Select at least one item": "至少选择一件商品",
		"Import tax: goods have been taxed": "进口税:商品已包税",
		"China": "中国",
		"U.S.A": "美国"
	},
	confirm: {
		"Confirm order information": "确认订单信息",
		"Delete item": "删除商品",
		"Consignee information": "收货人信息",
		"More addresses": "更多地址",
		"Add address": "添加地址",		
		"Total amount of goods": "商品总金额",		
		"freight": "运费",		
		"Total amount payable": "应付总额",		
		"To": "寄送至",
		"place order": "提交订单",
		"Select address": "选择地址",		
		"Use address": "使用地址",
		"Order submission in progress": "订单提交中"
	},
	callback: {
		"Open wechat code scanning payment": "打开微信扫码支付",		
		"Confirm payment": "确认支付",		
		"Cancel payment": "取消支付",		
		"View order": "查看订单",		
		"Payment query in progress": "支付查询中",		
		"Payment failed": "支付失败",		
		"Payment succeeded": "支付成功"
	},
	personal: {
		"modify the Avatar": "点击修改头像",
		"nickname": "昵称",
		"integral": "积分",
		"ID No": "ID号",
		"Change Password": "修改密码",
		"Forget Password": "忘记密码",
		"Log out": "退出登录",
		"Original password": "原密码",
		"Please enter the original password": "请输入原密码",
		"New password": "新密码",
		"Please enter a new password": "请输入新密码",
		"Please enter the new password again": "请重复输入新密码",
		"Confirm": "确 认",
		"Cancel": "取 消",
		"personal information": "个人信息",
		"recommend": "种草频道",
		"My order": "我的订单",
		"Custom records": "定制记录",
		"Address management": "地址管理",
		"Password modified successfully": "密码修改成功",
		"Identity information has expired, please log in again": "身份信息已过期，请重新登录",
		"Avatar modified successfully": "头像修改成功",
		"Exit login": "是否退出登录",
		"Tips": "提示",
		"sign out": "退 出",
		"Exit successful": "退出成功"
	},
	masage: {
		"edit": "编辑",
		"Please enter a nickname": "请输入昵称",
		"Gender": "性别",
		"secrecy": "保密",
		"female": "女",
		"male": "男",
		"birthday": "生日",
		"Select birthday": "选择生日",
		"Bind mailbox": "绑定邮箱",
		"Unbound mailbox": "未绑定邮箱",
		"Modify binding": "修改绑定",
		"Binding mobile phone": "绑定手机",
		"Unbound phone": "未绑定手机",
		"Save now": "立即保存",
		"Mobile number binding": "手机号绑定",
		"Original mobile number": "原手机号",
		"Please enter your mobile number": "请输入手机号",
		"New mobile number": "新手机号",
		"Please enter a new phone number": "请输入新手机号",
		"Mailbox binding": "邮箱绑定",
		"Original mailbox": "原邮箱",
		"Please enter email account": "请输入邮箱账号",
		"New mailbox": "新邮箱",
		"Please enter a new email account": "请输入新邮箱账号",
		"Binding modification succeeded": "绑定修改成功",
		"Save change information": "是否保存更改信息",
		"Change succeeded": "更改成功",
		"Cancel modifying information": "是否取消修改信息",
		"return": "返回",
		"Verification code has been sent to email, please check": "验证码已发送至邮箱,请注意查收",
		"The verification code has been sent to the mobile phone, Please check it": "验证码已发送至手机,请注意查收"
	},
	order: {
		"whole": "全部",
		"To be paid": "待付款",
		"To be shipped": "待发货",
		"To be received": "待收货",
		"To be evaluated": "待评价",
		"Completed": "已完成",
		"Cancelled": "已取消",
		"Order status": "订单状态",
		"order number": "订单号",
		"Product name": "产品名称",
		"quantity": "数量",
		"model": "型号",
		"original price": "原价",
		"Preferential unit price": "优惠单价",
		"Total price": "总价",
		"Discount": "优惠",
		"Actual payment": "实付款",
		"Delete order": "删除订单",
		"cancellation of order": "取消订单",
		"View details": "查看详情",
		"Skin test report": "测肤报告",
		"Pay now": "立即支付",
		"Logistics information": "物流信息",
		"Confirm receipt": "确认收货",
		"Request invoice": "申请发票",
		"Invoice details": "发票详情",
		"Order details": "订单详情",
		"Consignee Name": "收货人姓名",
		"Consignee alias": "收货人别名",
		"Consignee telephone": "收货人电话",
		"Express name": "快递名称",
		"Harvest area": "收获地区",
		"Harvest detailed address": "收获详细地址",
		"order number": "订单号",
		"Order time": "下单时间",
		"Order status": "订单状态",
		"Original order price": "订单原价",
		"Favorable Price": "优惠价格",
		"Paid in price": "实付价格",
		"Online payment": "在线付款",
		"Amount payable": "应付金额",
		"Receiving address": "收货地址",
		"coupon": "优惠券",
		"Select coupon": "选择优惠券",
		"Payment method": "支付方式",
		"Actual payment": "实付款",
		"payment": "支付",
		"Invoice No": "发票编号",
		"courier number": "快递单号",
		"Date of application": "申请日期",
		"Recipient name": "收件人姓名",
		"Full name of enterprise": "企业全称",
		"phone number": "手机号码",
		"e-mail address": "邮箱地址",
		"Invoiced amount": "开票金额",
		"Actual invoiced amount": "实际开票金额",
		"Drawer": "开票人",
		"Billing date": "开票日期",
		"Contact address": "联系地址",
		"Modify information": "修改信息",
		"personal": "个人",
		"full name": "姓名",
		"Please enter your name": "请输入姓名",
		"mailbox": "邮箱",
		"Please enter email": "请输入邮箱",
		"Submit": "提交",
		"enterprise": "企业",
		"Enterprise name": "企业名称",
		"Please enter the enterprise name": "请输入企业名称",
		"Enterprise tax No": "企业税号",
		"Please enter the enterprise tax number": "请输入企业税号",
		"Business address": "企业地址",
		"Please enter the business address": "请输入企业地址",
		"Telephone number": "电话号码",
		"Please enter the phone number": "请输入电话号码",
		"Deposit bank": "开户银行",
		"Please enter the deposit bank": "请输入开户银行",
		"bank account": "银行账号",
		"Please enter the bank account number": "请输入银行账号",
		"Do you want to delete this order? It will not be displayed after deletion": "是否删除此订单，删除后将不再展示",
		"delete": "删除",
		"cancel": "取消",
		"Deletion succeeded": "删除成功",
		"Cancel this order": "是否取消此订单",
		"confirm": "确认",
		"Cancellation succeeded": "取消成功",
		"Confirm receipt of the goods": "确定收到此货物",
		"determine": "确定",
		"Invoice application succeeded": "发票申请成功",
		"Invoice information modified successfully": "发票信息修改成功",
		"Order info": "订单信息",
		"Order No": "订单编号",
		"Receiving information": "收货信息",
		"Select payment": "请选择支付方式"
	},
	customized: {
		"Modify name": "修改名称",
		"Please enter a title": "请输入标题",
		"Related orders": "相关订单",
		"Customization details": "定制详情",
		"Successfully edited": "编辑成功",
		"Customized guidance information": "您希望用哪种方式进行定制？",
		"Send link": "发送链接",
		"Skin Analysis": "分析"
	},
	address: {
		"consignee": "收货人",
		"alias": "别名",
		"cell-phone number": "手机号",
		"Location": "所在地区",
		"Detailed address": "详细地址",
		"Paste all content smart identification": "粘贴所有内容智能识别",
		"Intelligent identification": "智能识别",
		"Please enter the consignee name": "请输入收货人姓名",
		"Please enter the consignee alias": "请输入收货人别名",
		"Please enter the mobile number of the consignee": "请输入收货人手机号",
		"Please enter the address": "请输入地址",
		"preservation": "保 存",
		"Default address": "默认地址",
		"Please enter the detailed harvest address": "请输入收获详细地址",
		"Please select an address": "请选择地址",
		"Edit information": "编辑信息",
		"Delete address information": "是否删除地址信息",
		"Set as default address": "是否设置为默认地址",
		"Set successfully": "设置成功",
		"Address analysis in progress": "地址分析中",
		"Please enter address information": "请输入地址信息",
		"Add address": "新增地址",
		"Please fill in the address": "请填写地址",
		"Successfully added address": "成功新增地址",
		"Address modified successfully": "地址修改成功",
		"Set as default": "设为默认"
	}
}