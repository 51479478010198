//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Customized from '@/component/Customized';
export default {
  data() {
    return {
      isCollapse: false,
      // 当前激活菜单的 index
      activeIndex: 'record',
      top: 0,
      navigation: true,
      country: '',
      search: '',
      content: '',
      hoverPopover: 100,
      popover: false,
      logo: require('../../assets/images/logo.jpg'),
      uCenterIcon: require('../../assets/icons/user.svg'),
      menu: [{
        name: '/',
        text: 'navigation.About special me',
        children: [{
          name: '/story',
          text: 'navigation.Brand Story'
        }, {
          name: '/press',
          text: 'navigation.Press'
        } // { name: 'faqs', text: 'navigation.FAQS' }
        ]
      }, // { name: '/record', text: 'navigation.Intelligent AI customization' },
      {
        name: '/product',
        text: 'navigation.Product introduction'
      }, {
        name: '/recommend',
        text: 'navigation.recommend'
      } // { name: '/personal', text: 'navigation.Personal Center', icon: require('../assets/icons/user.svg') }
      ],
      userInfo: '',
      test: 0
    };
  },

  components: {
    Customized
  },
  computed: {
    isMobile() {
      return /Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(navigator.userAgent);
    },

    hadLogin() {
      return this.userInfo?.user_sn || false;
    },

    menuActiveList() {
      return this.menu.map(i => {
        return i.name;
      });
    }

  },
  watch: {
    '$route'(to, form) {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo')) || '';
      const {
        menuActiveList
      } = this;
      const path = to.path.split('/')[1];
      console.log(path, menuActiveList, this.userInfo);

      if (menuActiveList.indexOf('/' + path) === -1) {
        this.activeIndex = null;
      } else {
        this.activeIndex = to.path;
      }
    }

  },

  mounted() {
    this.country = localStorage.getItem('country') || 'en';
    this.userInfo = JSON.parse(localStorage.getItem('userInfo')) || '';
    window.addEventListener('click', e => {
      if (this.isCollapse) {
        this.isCollapse = false;
      }
    });
  },

  beforeDestroy() {// 在组件生命周期结束的时候要销毁监听事件，否则其他页面也会使用这个监听事件
    // window.removeEventListener("scroll", this.handleScroll0);
  },

  methods: {
    handleOpen() {},

    handleClose() {},

    // logo click
    handleLogoClick() {
      const current = location.hash.slice(2);

      if (current) {
        this.$router.push({
          path: "/"
        });
      } else {
        document.documentElement.scrollTop = 0;
      }
    },

    // 导航栏菜单激活回调
    handleSelect(index, path) {
      this.activeIndex = index;
      console.log('handleSelect', index, path, this.activeIndex);
    },

    // 解决导航栏不能滚动的问题
    handleScroll0() {
      let sl = -Math.max(document.body.scrollLeft, document.documentElement.scrollLeft);
      document.getElementById('fixed').style.left = sl + 'px';
      let scrollTop = window.pageYOffset;
      this.navigation = scrollTop <= this.top;
      this.top = scrollTop;
    },

    language() {
      if (this.country == 'zh') {
        this.country = 'en';
        this.$i18n.locale = 'en';
      } else {
        this.country = 'zh';
        this.$i18n.locale = 'zh';
      }

      this.$boss.$emit('langer', this.country);
      localStorage.setItem('country', this.country);
      window.location.reload();
    }

  }
};