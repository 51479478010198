import axios from "axios";
import { Message } from 'element-ui';
axios.defaults.baseURL = 'https://specialmeapi-test.aacclub.cn'
// axios.defaults.headers["Content-Type"] = "multipart/form-data";
axios.interceptors.request.use(res => { 
	const lang = localStorage.getItem('country')
	const { url } = res
	if (url.indexOf('http') === -1) {
		res.headers = {
			...res.headers,
			application_client_type: 'pc',
			application: 'web',
			lang,
			country: 'us'
		}
	}
	return res 
}, err => { return err })
axios.interceptors.response.use(res => { 
	const { code, msg } = res.data
	console.log(res.data)
	if (code && code !== 0) {
		Message({
			type: 'error',
			message: msg
		});
		if (code === -400) {
			const { origin, hash } = window.location
			const whiteList = ['questionnaire']
			let isWhite = false
			for (let white in whiteList) {
				if (white.indexOf(white) !== -1) {
					isWhite = true
					break;
				}
			}
			if (!isWhite) {
				window.location.href = origin + "/#/login";
			}
		}
	}
	return res.data
}, err => { return err })
export default axios